@import '../../styles/customMediaQueries.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  width: calc(100% - 48px);
  max-width: 1080px;
  margin: 24px auto;
  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}
.title {
  line-height: 1.4;
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  text-align: center;
  color: var(--colorBlack10);
  @media (--viewportMedium) {
    font-size: 44px !important;
  }
}
.contentTitle {
  color: var(--colorBlack10);
  font-weight: var(--fontWeightBold) !important;
  margin-bottom: 0;
}
.root p {
  font-size: 14px;
  font-weight: var(--fontWeightMedium);
  margin: 4px 0;
}
.list > p:first-child {
  font-weight: var(--fontWeightBold);
  color: var(--colorBlack);
}
.list > ul {
  margin-top: 0;
}
.list > ul li p {
  margin: 4px 0;
}
.list > ul li {
  font-weight: var(--fontWeightBold);
  font-size: 14px;
}
.listStyleTwo {
  list-style: inside;
  margin-top: 0;
}
.listStyleTwo li {
  font-weight: var(--fontWeightMedium) !important;
}
.specificSubTitle {
  font-weight: var(--fontWeightBold) !important;
  font-size: 14px;
  color: #4a4a4f !important;
}
